var overy1;
var overy2;
var scrollDown = 0;
var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    widthviewport = w.innerWidth || e.clientWidth || g.clientWidth,
    heightviewport = w.innerHeight|| e.clientHeight|| g.clientHeight;


function startovery(){
    clearTimeout(overy2);
    // Disable
    $('body').on({ 'mousewheel': function(e) {                
        e.preventDefault();
    }})
    document.ontouchstart = function(e){ e.preventDefault(); }
    overy1 = setTimeout(function() {
        $("body").css({"overflow-y":"auto"});
    }, 0);
    // enable
    $('body').unbind('mousewheel DOMMouseScroll').on({ 'mousewheel': function(e){ }})
    document.ontouchstart = function(e){ return true; }
}


function endovery(){
    // Disable
    clearTimeout(overy1);    
    $('body').on({ 'mousewheel': function(e) {                
        e.preventDefault();
    }})    
    document.ontouchstart = function(e){ e.preventDefault(); }
    overy2 = setTimeout(function() {
        // do
        // console.log('delay 1s then do it');
        $("body").css({"overflow-y":"hidden"});
    }, 0);
    // enable
    $('body').unbind('mousewheel DOMMouseScroll').on({ 'mousewheel': function(e){ }})
    document.ontouchstart = function(e){ return true; }
}


$(document).ready(function(){
    
    // menu header active when responsive
    $(".ico--nav__wrapper, .menu__link, .icon-close-menu").bind('click', function(e){
        if ($(this).hasClass("nav-open")){
            $('.header').hide();
            $(".ico--nav__wrapper").removeClass("nav-open").addClass("nav-close");
            // $(".ico--nav").addClass("animate");
            $(".navmenu-m").addClass("active");
        }
        else{
            $('.header').show();
            $(".ico--nav__wrapper").removeClass("nav-close").addClass("nav-open");
            // $(".ico--nav").removeClass("animate");            
            $(".navmenu-m").removeClass("active");
        }        
    });

    // first scroll dan buat header tranparent 
    $(function(){
        
        var widthScreen = $(window).width();

        if(widthScreen <= 768) {
            $(window).on('scroll', function(){
                var scrollTop = $(window).scrollTop();
                // console.log(scrollTop);
                
                if(scrollTop > 0) {
                    $('.header').addClass('header--alpha');
                }

                if(scrollTop === 0) {
                    $('.header').removeClass('header--alpha');
                }
            });
        }

        if(widthScreen > 768 && widthScreen <= 2000) {
            $(window).on('scroll', function(){
                var scrollTop = $(window).scrollTop();
                // console.log(scrollTop);
                
                if(scrollTop > 821) {
                    $('.header').addClass('header--alpha');
                }

                if(scrollTop <= 821) {
                    if($('.header').hasClass('header--alpha')) {
                        $('.header').removeClass('header--alpha');
                    }   
                }
            });
        }

    }());

    // section page active when responsive
    // $(".ico--section, .menu-page__li .circle").bind('click', function(e){
    //     if ($(".menu-page").hasClass("show-m")){
    //         $(".menu-page").removeClass("show-m");
    //     }
    //     else{
    //         $(".menu-page").addClass("show-m");
    //     }        
    // });

    if (widthviewport > 980){
        // navbar
        // $('.menu__item').hover( 
        //     function() {
        //         $(this).removeClass('hover--current').addClass('hover--current');
        //     },
        //     function() {
        //         $(this).removeClass('hover--current');
        //     }
        // );

        var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        var body = document.documentElement || document.body;
        // var scrollBottom = $(window).scrollTop() + $(window).height();    
        // var scrollTop = this.pageYOffset || body.scrollTop;

        if(bodyScrollTop <= $("#section1").innerHeight()){
            if ( bodyScrollTop == 0 ){
                // scrollDown = 3;              
                $(".bg--main, .textmain").removeClass("active");
                $(".menu-page").addClass("hide");
                $(".arrow-to__section2").show();
                $(".arrow-to__redbg").hide();
                // $("body").css({"overflow-y":"hidden"});
            }
            else{
                $(".textmain").addClass("active"); 
            }
        }
        else{
            $(".textmain").addClass("active"); 
        }    

        // $(".arrow-to__redbg").bind('click', function(e){
        //     scrollDown = 1;         
        //     setTimeout(function() {
        //         // do
        //         // console.log('delay 1s then do it');
        //         $(".bg--main, .textmain, .section1__content").addClass("active");
        //         $(".arrow-to__section2").show();
        //         $(".arrow-to__redbg").hide();
        //         $(".menu-page").removeClass("hide");
        //     }, 500);
        // });

        $(".arrow-to__section2, .menu-page__li").bind('click', function(e){
            $("body").css({"overflow-y":"auto"});
        });
    }

    if (widthviewport > 768){
        /*** Scroll Magic ***/
        // init controller
        var controller = new ScrollMagic.Controller();
        // sticky header
        new ScrollMagic.Scene({triggerElement: "#about, #section2", triggerHook: 0, offset: -150}).setClassToggle(".header", "sticky")
            // .addIndicators({name: "sticky"})
            .addTo(controller);
    }
});

if (widthviewport > 980){
    // after load
    $(window).load(function() {
        // class animate
        $('.menu-page__li').addClass('animate');
    });

    // when scroll
    $(document).scroll(function() {
        var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        var body = document.documentElement || document.body;
        var scrollPos = ($(document).scrollTop() + 5);

        // menu header active when link & scroll 
        $('.menu-page a').each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr("href").match(/#(.*$)/)[0]);
            // console.log(refElement);
            if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                $('.menu-page ul li').removeClass("active");
                currLink.parent().addClass("active");
            }
            else{
                currLink.parent().removeClass("active");
            }
        });

        if( bodyScrollTop == 0 ) {
            scrollDown = 0;        
            // endovery();
            $(".menu-page").addClass("hide");
            $(".bg--main, .textmain, .section1__content").removeClass("active");
            // $(".arrow-to__section2").hide();
            // $(".arrow-to__redbg").show();
            // if( scrollDown < 4 ) {
            //     scrollDown = 3;
            // } 
        }
        // else if (((body.scrollHeight - bodyScrollTop) - 600) < parseFloat(body.clientHeight)) {
        //     $(".menu-page").addClass("hide");
        // }
        else{
            $(".bg--main, .textmain, .section1__content").addClass("active");
            $(".menu-page").removeClass("hide");
        }
    });


    // when scroll up/down with mousewheel
    $(window).bind('mousewheel DOMMouseScroll', function(e){
        var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        var body = document.documentElement || document.body;
        var direction = (function () {
            var delta = (e.type === 'DOMMouseScroll' ? e.originalEvent.detail * -40 : e.originalEvent.wheelDelta);
            return delta > 0 ? 0 : 1;
        }());

        if(direction === 1) {
            // console.log('Scroll down');        
            scrollDown = scrollDown + 1;
            if ( bodyScrollTop == 0 && scrollDown < 4 ) {
                // console.log(scrollDown);
                // scrollDown = 1;           
                //  endovery();
                setTimeout(function() {
                    $(".bg--main, .textmain, .section1__content").removeClass("active");
                    // $(".arrow-to__section2").hide();
                    // $(".arrow-to__redbg").show();
                    $(".menu-page").addClass("hide");
                }, 0);
                if ( scrollDown == 0 ) {
                    scrollDown = scrollDown + 3;
                }
                else if ( scrollDown == 1 ) {
                    scrollDown = scrollDown + 2;
                }
                else if ( scrollDown == 2 ) {
                    scrollDown = scrollDown + 1;
                }
            }               
            else if ( bodyScrollTop == 0 && scrollDown < 5 ) {             
                // console.log(scrollDown);
                // scrollDown = 4;           
                setTimeout(function() {
                    // do
                    // console.log('delay 1s then do redbg');
                    $(".bg--main, .textmain, .section1__content").addClass("active");
                    // $(".arrow-to__section2").show();
                    // $(".arrow-to__redbg").hide();
                    $(".menu-page").removeClass("hide");
                    if (((body.scrollHeight - bodyScrollTop) - 600) < parseFloat(body.clientHeight)) {
                        $(".menu-page").addClass("hide");
                    }
                }, 0);            
            }   
            else{
                // console.log(scrollDown);
                // scrollDown = 5;
                // setTimeout(function() {                
                //     startovery();
                // }, 0); 
            }              
            
        }
        if(direction === 0) {
            // console.log('Scroll up');
            // scrollDown = 0;                       
            if ( bodyScrollTop == 0 ) {
                // console.log('Scroll up 2');
                scrollDown = 0;
                $(".bg--main, .textmain, .section1__content").removeClass("active");
                // $(".arrow-to__section2").hide();
                // $(".arrow-to__redbg").show();  
                $(".menu-page").addClass("hide");
                // endovery();                 
            }        
        }
    });

}



function comingSoon() {
    var wW = $('body').width(),
            count = $(".project__other__li:not(.coming-soon)").length,
            perLine = 1;

    $('.coming-soon').hide();

        if (wW >= 601) {
            perLine = 2;
            //$('.work').css({width: '50%'});
        }
        if (wW > 980) {
            perLine = 3;
            //  $('.work').css({width: parseInt($('body').width()/3)});
        }
        if (wW > 1440) {
            perLine = 4;
            //  $('.work').css({width: '25%'});
        }
        // console.log(count - count % perLine);
        if (count % perLine !== 0) {
            var comingSoonCount = ((count - count % perLine) + perLine) - count;

            for (var i = 0; i < comingSoonCount; i++) {
                $('.coming-soon').eq(i).show();
            }
        } else {
            for (var i = 0; i < perLine; i++) {
                $('.coming-soon').eq(i).show();
            }
        }
}

$(window).resize(function () {
    comingSoon();

});

$(window).load(function () {
    comingSoon();

})